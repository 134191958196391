
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            isActive: false
        }
    },
    props: {
        title: String,
        text: String,
        button: Boolean,
        imgNumber: String | Number,
        buttonFunc: {
            type: Function,
            default: () => true
        },
        additionalClass: {
            type: String,
            default: ''
        },
        banerText: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
			settings: 'settings/settings/settings',
		}),
        settings_sale_credit(){
            return this.settings.sale_credit?this.settings.sale_credit:''
        },
        src1() {
            return {
                x1: require(`~/assets/img/baners/baner-${this.imgNumber}@x1.webp`),
                x2: require(`~/assets/img/baners/baner-${this.imgNumber}@x2.webp`)
            };
        },
        src2() {
            return {
                x1: require(`~/assets/img/baners/baner-${this.imgNumber}-green@x1.webp`),
                x2: require(`~/assets/img/baners/baner-${this.imgNumber}-green@x2.webp`)
            };
        }
    }
}
