import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0bfd79e5&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MakesMiniSlider: require('/home/d/didisher2/pioner-cars.ru/www/components/makes/makes-mini-slider.vue').default,Makes: require('/home/d/didisher2/pioner-cars.ru/www/components/makes/makes.vue').default,CatalogListIndex: require('/home/d/didisher2/pioner-cars.ru/www/components/catalog/catalog-list/catalog-list-index.vue').default,Baner: require('/home/d/didisher2/pioner-cars.ru/www/components/baner/baner.vue').default,FeaturedBank: require('/home/d/didisher2/pioner-cars.ru/www/components/featured/featured-bank.vue').default,MapMain: require('/home/d/didisher2/pioner-cars.ru/www/components/map/map-main.vue').default,SeoTextHome: require('/home/d/didisher2/pioner-cars.ru/www/components/seo-text/seo-text-home.vue').default})
