
import seoTags from "@/mixins/seoTags";
// import LazyHydrate from 'vue-lazy-hydration';
export default {
	mixins: [seoTags],
	// 	components: {
	//     LazyHydrate,
	//   },
	data(){
		return {
			baner_text:'Получите индивидуальное предложение по автокредитованию в ПИОНЕРАВТО на удобных для Вас условиях без справок, предоставив только паспорт и водительское удостоверение. Заявка будет рассмотрена в течение 30 минут.'
		}
	},
	mounted() {
		setTimeout(function () {
			window.scrollTo(0, -100);
		}, 1);
	},

}
