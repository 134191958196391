
import { mapActions, mapGetters, mapMutations } from "vuex";
import banks from "@/apollo/queries/bank/banks";

export default {

	data() {
		return {
			sw: null
		}
	},
	async fetch() {
		let response = await this.request({ query: banks, variables: {} })
		// if (this.$route.path != '/banks') {
		// 	response.data.banks = response.data.banks.splice(0, 16)
		// }
		await this.setBanks(response.data.banks)
	},
	methods: {
		...mapMutations({
			setBanks: 'banks/SET_BANKS'
		}),
		...mapActions({
			request: 'filters/filters/request',
		}),
	},
	computed: {
		...mapGetters({
			banks: 'banks/banks',
		}),
		mainBanks() {
			return this.banks.filter(x => x.name === "Сбербанк" | x.name === "Альфа-Банк" | x.name === "Тинькофф Банк")
		},
		otherBanks() {
			return this.banks.filter(x => x.name !== "Сбербанк" && x.name !== "Альфа-Банк" && x.name !== "Тинькофф Банк")
		}
	},
	mounted() {
		if (this.$route.path !== '/banks')
			this.sw = new swiper.default('.swiper.swiper--banks', {
				modules: [swiper.Navigation, swiper.Autoplay, swiper.Pagination, swiper.Grid],
				autoplay: false,
				initialSlide: 0,
				watchSlidesProgress: true,
				pagination: {
					clickable: true,
					dynamicBullets: this.$device.isMobile ? true : false,
					dynamicMainBullets: 3,
					el: ".swiper-pagination.swiper-pagination--banks",
					type: "bullets",
				},
				slidesPerView: 2,
				spaceBetween: 10,
				// grid: {
				// 	fill: 'rows',
				// 	rows: 3,

				// },
				breakpoints: {
					// when window width is >= 320px
					// 220: {
					// 	slidesPerView: 1.25,
					// 	spaceBetween: 20
					// },
					600: {
						slidesPerView: 4,
						spaceBetween: 30,
						slidesPerGroup: 4
						// grid: {
						// 	fill: 'rows',
						// 	rows: 1,

						// },
					},
					1300: {
						slidesPerView: 6,
						spaceBetween: 30,
						slidesPerGroup: 6
					}
				},
				navigation: {
					nextEl: '.swiper-button-next--banks',
					prevEl: '.swiper-button-prev--banks',
				},
			})


		// sw.on('slideChange', (swiper) => this.activeSlide = swiper.activeIndex)
		// })
	}
}
