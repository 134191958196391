
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	props: {
		forChoseModal: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => {
			}
		},
		active: {
			type: Boolean,
			default: false
		},
		slug: {
			type: String,
			default: ''
		},
		visibleIcon: {
			type: Boolean,
			default: false
		},
		onlyMarks: Boolean,
		svg: {
			typeof: Boolean,
			default: true
		}
	},
	methods: {
		...mapMutations({
			setModalSearch: 'modal/modal-search/setModalSearch',
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks'
		}),
		linkClick() {
			this.setModalMenu(false)
			this.$router.push(this.link)
		}
	},
	computed: {
		link() {
			if (this.onlyMarks) {
				return '/cars/' + this.slug
			} else {
				return !this.$route.params.mark ? 'cars/' + this.slug : this.$route.path + '/' + this.slug
			}
		}
	},


}
